import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { DcuplQueryBuilder } from '@dcupl/common';
import { Dcupl, DcuplList } from '@dcupl/core';
import { LisFormFieldCheckbox } from '@lis-form';
import { getCharcodeHashFromString } from '@lis-helpers';
import { DcuplService } from '@lis-services';
import {
  LisTableModel,
  LisTableMultiSelectGroup,
  LisTableMultiSelectOptions,
} from '@lis-types';
import { first, orderBy } from 'lodash-es';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'lis-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent<
    Model extends LisTableModel,
    OptionKey extends string,
  >
  implements OnInit, OnDestroy
{
  @Input({ required: true }) dcuplList!: DcuplList;
  @Input({ required: true }) selectOptions!: LisTableMultiSelectOptions<Model>;

  @Output() selected = new EventEmitter<string[] | null>();

  @ViewChild('inputElement') inputElement?: { nativeElement: HTMLInputElement };

  public clickOutsideId = '';

  public isOverlayOpen = false;

  public availableSelectGroups?: LisTableMultiSelectGroup<OptionKey>[] | null;
  public currentFilters: string[] = [];
  public displayedValue = '';

  private formGroup = new FormGroup({});
  public inputFormControl = new FormControl<string | null>(null);

  private subscriptions = new Subscription();

  private controlKeyValueMap: { [key: string]: string } = {};

  private dcupl?: Dcupl;

  private refreshOptions$ = new Subject<void>();

  constructor(
    private cdRef: ChangeDetectorRef,
    private dcuplService: DcuplService
  ) {}

  ngOnInit(): void {
    this.clickOutsideId =
      this.selectOptions.optionKey.toString() + '-multi-select';
    this.dcupl = this.dcuplService.getRoleBasedDcupl();
    this.initCheckboxFormGroup();
    this.listenForRefreshOptions();
    this.listenForSearchTermChange();
    this.listenForFormValueChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private listenForRefreshOptions(): void {
    this.subscriptions.add(
      this.refreshOptions$.subscribe(() => {
        // set Timeout to ensure the outside click event is triggered before updating the DOM with new options
        setTimeout(() => {
          this.availableSelectGroups = this.getGroupedSelectOptions();
          this.cdRef.markForCheck();
        });
      })
    );
  }

  private listenForSearchTermChange(): void {
    this.subscriptions.add(
      this.inputFormControl.valueChanges.subscribe(() => {
        this.refreshOptions$.next();
      })
    );
  }

  public onInputFocus(): void {
    this.refreshOptions$.next();
  }

  private initCheckboxFormGroup(): void {
    const allSelectGroups = this.getGroupedSelectOptions('all');

    allSelectGroups.forEach((group) => {
      group.options.forEach((option) => {
        this.controlKeyValueMap[option.key] = option.innerLabel;
        this.formGroup.addControl(option.key, new FormControl('unchecked'));
      });
    });
  }

  private listenForFormValueChanges(): void {
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe((value) => {
        this.currentFilters = Object.keys(value).filter(
          (key: string) =>
            (value as { [key: string]: string })[key] === 'checked'
        );

        if (this.currentFilters.length > 1) {
          this.displayedValue = '';
        } else if (this.currentFilters.length === 1) {
          const key = first(this.currentFilters);
          if (key) {
            this.displayedValue = this.controlKeyValueMap[key] ?? '';
          }
        }

        if (!this.selectOptions || !this.dcuplList) {
          return;
        }

        const queries = this.currentFilters.map((key) => {
          return {
            attribute: this.selectOptions.optionKey.toString(),
            operator: 'eq',
            value: this.controlKeyValueMap[key],
          };
        });

        if (this.currentFilters.length) {
          this.dcuplList.catalog.query.apply(
            {
              groupKey: this.selectOptions.optionKey.toString(),
              groupType: 'or',
              queries,
            },
            { mode: 'set' }
          );
        } else {
          this.dcuplList.catalog.query.remove({
            groupKey: this.selectOptions.optionKey.toString(),
          });
        }

        this.selected.emit(
          this.currentFilters.length ? this.currentFilters : null
        );

        let shouldResetAll = true;

        this.availableSelectGroups?.forEach((group) => {
          group.options.forEach((option) => {
            if (
              this.formGroup.get(option.key.toString())?.value === 'checked'
            ) {
              shouldResetAll = false;
            }
          });
        });

        if (shouldResetAll && this.formGroup.touched) {
          this.formGroup.reset();
          return;
        }

        this.formGroup.markAsTouched();
      })
    );
  }

  public onCheckAllStatesByGroup(groupKey: string): void {
    const group = this.availableSelectGroups?.find(
      (group) => group.groupKey === groupKey
    );
    if (!group) {
      return;
    }

    if (this.isWholeGroupChecked(group)) {
      return group.options.forEach((option) => {
        this.formGroup.get(option.key.toString())?.setValue('unchecked');
      });
    }

    return group.options.forEach((option) => {
      return this.formGroup.get(option.key.toString())?.setValue('checked');
    });
  }

  public isWholeGroupChecked(
    group: LisTableMultiSelectGroup<OptionKey>
  ): boolean {
    return group.options.every(
      (option) => this.formGroup.get(option.key)?.value === 'checked'
    );
  }

  public onResetFilter(): void {
    this.formGroup.reset();
  }

  public onCheckBoxClick(key: string): void {
    const control = this.getCheckboxControlByKey(key as OptionKey);
    if (!control) {
      return;
    }

    control.setValue(control.value === 'checked' ? 'unchecked' : 'checked', {
      emitModelToViewChange: true,
    });
    if (this.inputFormControl.value?.length) {
      this.inputElement?.nativeElement.focus();
    }
  }

  private getGroupedSelectOptions(
    fetch?: 'all'
  ): LisTableMultiSelectGroup<OptionKey>[] {
    const modelKey = this.dcuplList.modelKey;

    const dcuplList = this.dcupl?.lists.create({
      modelKey,
    });
    if (!dcuplList) {
      return [];
    }

    const currentQuery = this.dcuplList.catalog.query.get();

    if (currentQuery) {
      dcuplList.catalog.query.apply(currentQuery);
    }
    dcuplList.catalog.query.remove({
      groupKey: this.selectOptions.optionKey.toString(),
    });

    if (!this.selectOptions.groupKey) {
      let searchString = this.inputFormControl.value ?? '';
      if (this.currentFilters.length) {
        searchString = searchString.concat('|' + this.currentFilters.join('|'));
      }

      const suggests = dcuplList.catalog.fn.suggest({
        attribute: this.selectOptions.optionKey as string,
        value: this.inputFormControl.value ? `/${searchString}/` : '//',
        transform: ['lowercase'],
        relevantData: 'excludeQuery',
        excludeQuery: {
          groupKey: this.selectOptions.optionKey as string,
        },
        max: 9999,
      });

      const options: LisFormFieldCheckbox<OptionKey>[] = orderBy(
        suggests,
        (suggest) => {
          return this.currentFilters.includes(suggest.value) ? 0 : 1;
        }
      ).map((item) => {
        return {
          type: 'checkbox',
          key: this.getUniqueKey(item.value) as OptionKey,
          innerLabel: item.value,
        };
      });

      return [
        {
          groupKey: null,
          options: fetch === 'all' ? options : options.slice(0, 10),
        },
      ];
    }

    const suggests = dcuplList.catalog.fn.suggest({
      attribute: this.selectOptions.groupKey as string,
      value: `//`,
      transform: ['lowercase'],
      relevantData: 'excludeQuery',
      excludeQuery: {
        groupKey: this.selectOptions.groupKey as string,
      },
    });

    const selectGroups: LisTableMultiSelectGroup<OptionKey>[] = [];

    suggests.forEach((suggest) => {
      const queryBuilder = new DcuplQueryBuilder();
      queryBuilder.init({
        modelKey,
      });

      const currentQuery = dcuplList.catalog.query.get();
      if (currentQuery) {
        queryBuilder.applyQuery(currentQuery);
      }
      queryBuilder.applyQuery({
        attribute: this.selectOptions.groupKey as string,
        operator: 'eq',
        value: suggest.value,
      });
      const query = queryBuilder.getQuery();

      const optionSuggests =
        this.dcupl?.fn.suggest({
          modelKey,
          options: {
            attribute: this.selectOptions.optionKey as string,
            value: `//`,
          },
          query,
        }) ?? [];

      const options: LisFormFieldCheckbox<OptionKey>[] = optionSuggests.map(
        (s) => ({
          type: 'checkbox',
          key: this.getUniqueKey(s.value) as OptionKey,
          innerLabel: s.value,
        })
      );

      selectGroups.push({
        groupKey: suggest.value,
        options,
      });
    });

    if (!this.selectOptions.customGroupOrder) {
      return orderBy(selectGroups, ['groupKey']);
    }
    return orderBy(selectGroups, [
      (item) =>
        this.selectOptions.customGroupOrder?.indexOf(item.groupKey as string),
    ]);
  }

  public getCheckboxControlByKey(
    key: OptionKey
  ): AbstractControl<string> | undefined {
    return this.formGroup.get(key.toString()) ?? undefined;
  }

  public onOutsideClick(): void {
    if (!this.selectOptions.groupKey) {
      this.inputFormControl.setValue(null);
    }
    this.isOverlayOpen = false;
  }

  public onOverlayToggle(onlyOpen?: boolean): void {
    if (onlyOpen && !this.selectOptions.groupKey) {
      this.isOverlayOpen = true;
      this.cdRef.markForCheck();
      return;
    }

    this.isOverlayOpen = !this.isOverlayOpen;
    if (this.isOverlayOpen) {
      this.inputElement?.nativeElement.focus();
    }
    this.cdRef.markForCheck();
  }

  public getUniqueKey(value: string): string {
    const hash = getCharcodeHashFromString(value);
    const transformedValue = value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]/g, '-')
      .trim();

    return `${transformedValue}-${hash}`;
  }

  public trackByGroup(index: number): number {
    return index;
  }
  public trackByOption(index: number, option: LisFormFieldCheckbox): string {
    return option.key.toString();
  }
}
