import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Directive({
  selector: '[lisClickOutside]',
})
export class ClickOutsideDirective {
  constructor(private _elementRef: ElementRef) {}

  @Output()
  public lisClickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) {
      return;
    }

    // *--------------------------------------------------------------------------*
    // to avoid firing the directive when clicking on exception elements
    // (e.g. a button that opens a dropdown that uses clickoutside) you can
    // define the said element as a "clickoutside exception" by adding the same
    // id as for the elementRef to it and appending "-clickoutside-exception" to it
    // *--------------------------------------------------------------------------*

    const exceptionElements = document.querySelectorAll(
      '#' + this._elementRef.nativeElement.id + '-clickoutside-exception'
    );

    const path = event.composedPath();

    const hasClickedOnExceptionElement = !!Array.from(exceptionElements).some(
      (element) => path.includes(element)
    );

    const hasClickedInside =
      this._elementRef.nativeElement.contains(targetElement) ||
      hasClickedOnExceptionElement;
    if (!hasClickedInside) {
      this.lisClickOutside.emit(event);
    }
  }
}
