<div class="w-full p-1 border border-dashed border-gray-300 rounded">
  <div class="py-3 px-5 flex flex-col gap-y-2 items-center justify-center rounded transition-colors duration-300" #dragArea>
    <div class="max-w-10 m-auto mb-2">
      <img src="assets/images/svg/big_upload-circle-on-light-128.svg" alt="file-upload" class="w-full block dark:hidden" />
      <img src="assets/images/svg/big_upload-circle-on-dark-128.svg" alt="file-upload" class="w-full hidden dark:block" />
    </div>
    <div>
      <input type="file" multiple class="hidden" (change)="onInputFileChanged($event)" accept="application/pdf" #input />
      <p>
        <button class="link" (click)="input.click()" type="button">{{ 'docs.upload-dialog.choose-file' | translate }}</button> {{ 'docs.upload-dialog.drag-file' | translate }}
      </p>
    </div>
    <p class="typo-body-s text-text-gray">
      {{ 'docs.upload-dialog.upload-information' | translate }}
    </p>
  </div>
</div>

<div *ngIf="this.files && this.files.length" class="mt-2">
  <p class="typo-body-xs text-text-gray">{{ 'docs.upload-dialog.filename' | translate }}</p>
  <div class="flex flex-col gap-y-1">
    <div *ngFor="let file of this.files; trackBy: trackByIndex" class="bg-label-background-default rounded p-2">
      <div class="flex justify-between">
        <div class="flex items-center space-x-2">
          <lis-icon [name]="'info_files-24'"></lis-icon>
          <p>{{ file.file.name }}</p>
        </div>
        <button type="button" aria-label="delete-file" (click)="onRemoveFileClick(file.file)"><lis-icon class="text-red-400" [name]="'action_delete-24'"></lis-icon></button>
      </div>
      <lis-toast *ngIf="file.status && file.status !== 'OK' && file.statusType" class="mt-2" [type]="file.statusType" [title]="getStatusToastContent(file.status).title | translate" [subtitle]="getStatusToastContent(file.status).subtitle | translate" [fullWidth]="true"></lis-toast>
    </div>
  </div>
</div>
